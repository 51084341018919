<template>
  <div>
    <Breadcrumbs main="Araç Firmaları" title="Liste" :links="breadLinks" />

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <h5>
                Toplam Borç :
                <b-badge variant="info"><c-money-cell :fiyat="toplamBorc" simge="₺"></c-money-cell></b-badge>
              </h5>
              <hr />
              <c-table :tablefields="tablefields" :items="items">
                <template #cell(Islem)="row">
                  <b-btn-group>
                    <router-link :to="`/aracFirma/aracFirmaDetay/${row.item.Id}`"
                      class="btn btn-info btn-xs">Detay</router-link>
                    <router-link :to="`/aracFirma/kayit/${row.item.Id}`"
                      class="btn btn-success btn-xs">Düzelt</router-link>
                    <router-link :to="`/aracFirma/aracList/${row.item.Id}`"
                      class="btn btn-warning btn-xs">Araçları</router-link>
                    <b-button size="xs" @click="sil(row.item)" variant="danger">Sil</b-button>
                  </b-btn-group>
                </template>
                <template #cell(ToplamAlacak)="data">
                  <c-money-cell :fiyat="data.value" simge="TL"></c-money-cell>
                </template>
                <template #cell(AlisUcreti)="data">
                  <c-money-cell :fiyat="data.value" simge="TL"></c-money-cell>
                </template>
                <template #cell(Email)="data">
                  <a :href="'mailto:' + data.value">{{ data.value }}</a>
                </template>
                <template #cell(SatisUcreti)="data">
                  <c-money-cell :fiyat="data.value" simge="TL"></c-money-cell>
                </template>
                <template #cell(KayitTarihi)="row">
                  <c-tarih-cell :kayitTarihi="row.item.KayitTarihi" :guncelTarih="row.item.GuncelTarih"
                    :rowKey="row.item.Id"></c-tarih-cell>
                </template>
              </c-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      breadLinks: [{ path: "/aracFirma/kayit", title: "Ekle", icon: "plus" }],
      tablefields: [
        { key: "Islem", label: "İşlemler", sortable: false },
        { key: "Adi", label: "Mekan Adı", sortable: true },
        { key: "YetkiliKisi", label: "Yetkili", sortable: true },
        { key: "GSM", label: "GSM", sortable: true, thClass: "sabitKol" },
        { key: "Email", label: "Email", sortable: true, thClass: "sabitKol" },
        { key: "ToplamAlacak", label: "Bakiye", sortable: true },
        { key: "DurumStr", label: "Durum", sortable: true },
        { key: "AlisUcreti", label: "Servis Alış", sortable: true },
        { key: "SatisUcreti", label: "Servis Satış", sortable: true },
        { key: "KullaniciAdi", label: "Kullanıcı", sortable: true },
        { key: "KayitTarihi", label: "Kayıt Tarihi", sortable: true },
      ],

      toplamBorc: 0,

      items: [],
    };
  },
  mounted() {
    this.onList();
  },
  methods: {
    onList() {
      let vm = this;

      this.$connection.get("/aracFirmalari", function (response) {
        vm.items = response;

        vm.toplamBorc = 0;
        vm.items.forEach((item) => {
          vm.toplamBorc += item.ToplamAlacak;
        });
      });
    },
    sil(item) {
      let vm = this;
      this.$alert.confirm(
        "Sil",
        "Araç firma kaydını sildiğinizde altındaki araçlarda etkilenecektir. Silmek istediğinize emin misiniz?",
        "warning",
        function () {
          vm.$connection.delete(
            "/aracFirmalari/" + item.Id,
            function (response) {
              vm.$alert.messageWithFunction(
                "Silindi",
                "Araç firma kaydı silindi.",
                "error",
                function () {
                  vm.$listHelper.removeItem(vm.items, item);
                }
              );
            }
          );
        }
      );
    },
  },
};
</script>
