<template>
  <div>
    <Breadcrumbs main="Acente" title="Kontrol Paneli" />

    <div class="container-fluid">
      <AcenteTurBekleyenler />

      <div class="row">
        <div class="col-xl-6" v-if="profilDurumu !== ''">
          <b-alert show variant="danger">
            <h4 class="alert-heading">Profil Durumunuz</h4>
            <p>
              Profilinizde bazı eksiklikler bulduk. Bunları doldurduğunuzda
              rezervasyon işlemlerinizi kolaylaştıracaktır.
            </p>
            <hr />
            <p v-html="profilDurumu" class="mb-3"></p>
            <p>
              <router-link to="/acente/profil" class="btn btn-dark">Güncelle</router-link>
              Profil sayfanızdan giriş yapıp Düzelt butonuna basarak gerekli
              güncellemeleri yapabilirsiniz.
            </p>
          </b-alert>
        </div>
        <div :class="'dashboard-reklam ' + (profilDurumu !== '' ? 'col-xl-6' : 'col-xl-12')">
          <Reklam :bannerTipi="4"></Reklam>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <ul class="nav nav-tabs border-tab tabs-scoial" id="top-tab" role="tablist">
            <li class="nav-item" v-on:click="changetab('dashboard')">
              <a class="nav-link" :class="[
          { 'show active': showtab == 'dashboard' },
          'shadow-lg p-25 shadow-showcase',
        ]" id="top-dashboard" data-toggle="tab" role="tab" aria-controls="dashboard" aria-selected="false">Kontrol
                Paneli<br>
                <i v-if="showtab == 'dashboard'" class="icon-angle-down"></i>
              </a>
            </li>
            <li class="nav-item" v-on:click="changetab('rezervasyon')">
              <a class="nav-link" :class="[
          { 'show active': showtab == 'rezervasyon' },
          'shadow-lg p-25 shadow-showcase bg-dark text-white',
        ]" id="top-rezervasyon" data-toggle="tab" role="tab" aria-controls="rezervasyon" aria-selected="true">PRIVATE
                TUR
                REZERVASYONU<br>
                <i v-if="showtab == 'rezervasyon'" class="icon-angle-down"></i>
              </a>
            </li>
            <li class="nav-item" v-on:click="changetab('regularrezervasyon')">
              <a class="nav-link" :class="[
          { 'show active': showtab == 'regularrezervasyon' },
          'shadow-lg p-25 shadow-showcase bg-info text-white',
        ]" id="top-regular-rezervasyon" data-toggle="tab" role="tab" aria-controls="regularrezervasyon"
                aria-selected="true">REGULAR TUR REZERVASYONU<br>
                <i v-if="showtab == 'regularrezervasyon'" class="icon-angle-down"></i>
              </a>
            </li>
            <li class="nav-item" v-on:click="changetab('balonrezervasyon')">
              <a class="nav-link" :class="[
          { 'show active': showtab == 'balonrezervasyon' },
          'shadow-lg p-25 shadow-showcase bg-warning text-white',
        ]" id="top-balon-rezervasyon" data-toggle="tab" role="tab" aria-controls="balonrezervasyon"
                aria-selected="true">BALON REZERVASYONU<br>
                <i v-if="showtab == 'balonrezervasyon'" class="icon-angle-down"></i>
              </a>
            </li>
            <li class="nav-item" v-on:click="changetab('transfer')">
              <a class="nav-link" :class="[
          { 'show active': showtab == 'balonrezervasyon' },
          'shadow-lg p-25 shadow-showcase bg-danger text-white',
        ]" id="top-transfer" data-toggle="tab" role="tab" aria-controls="transfer" aria-selected="true">TRANSFER
                REZERVASYONU<br>
                <i v-if="showtab == 'transfer'" class="icon-angle-down"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="tab-content" id="top-tabContent">
        <div class="tab-pane fade" :class="{ 'show active': showtab == 'dashboard' }" id="dashboard" role="tabpanel"
          aria-labelledby="dashboard">
          <div class="row">
            <div class="col-xl-12">
              <div class="card">
                <div class="card-body">
                  <TurRezervasyonList />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="tab-pane fade" :class="{ 'show active': showtab == 'rezervasyon' }" id="rezervasyon" role="tabpanel"
          aria-labelledby="rezervasyon">

          <b-tabs active-nav-item-class="bg-info text-white" class="private-rez-form">
            <b-tab title-link-class="p-3" title="PRIVATE TUR REZERVASYONU VER" active>
              <TurRezervasyonForm @onComplete="onComplete" :misafirler="tasinacakTurMisafirler" :acenteId="acenteId">
              </TurRezervasyonForm>

              <b-modal id="turRezervasyonModel" ref="turRezervasyonModel" size="xl" centered title="Tur Rezervasyonu"
                ok-title="Save Changes" class="theme-modal" :hide-footer="true">
                <div class="container-fluid bd-example-row grid-showcase">
                  <div class="row">
                    <div class="col-md-12 text-center mb-3">
                      <h3>
                        <feather type="check-circle" class="text-success"></feather> {{ turRezervasyonMessage }}
                      </h3>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 ml-auto"><b-button variant="danger" @click="$router.push('/')" block><i
                          class="fa fa-times"></i> KAPAT</b-button></div>
                    <div class="col-md-6  ml-auto">
                      <b-button block @click="turRezDetayGit()" variant="info"><i class="fa fa-info-circle"></i> DETAYA
                        GİT</b-button>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="card">
                        <div class="card-body">
                          <div class="media feather-main">
                            <div class="img-icon-block">
                              <img src="../../assets/images/icons/private.png" alt="" />
                            </div>
                            <div class="media-body align-self-center">
                              <h6>YENİ PRIVATE TUR REZERVASYONU</h6>
                              <p>AYNI MİSAFİRLER için farklı bir PRIVATE tur rezervasyonu mu vermek istiyorsunuz?</p>
                              <b-button block @click="yeniTurRezervasyonuVer()" variant="dark">Private Tur Rezervasyonu
                                Ver</b-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="card">
                        <div class="card-body">
                          <div class="media feather-main">
                            <div class="img-icon-block">
                              <img src="../../assets/images/icons/regular.png" alt="" />
                            </div>
                            <div class="media-body align-self-center">
                              <h6>YENİ REGULAR TUR REZERVASYONU</h6>
                              <p>AYNI MİSAFİRLER için REGULAR tur rezervasyonu mu vermek istiyorsunuz?</p>
                              <b-button block @click="yeniRegularRezervasyonuVer()" variant="primary">Regular Tur
                                Rezervasyonu Ver</b-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-md-6">
                      <div class="card">
                        <div class="card-body">
                          <div class="media feather-main">
                            <div class="img-icon-block">
                              <img src="../../assets/images/icons/balon.png" alt="" />
                            </div>
                            <div class="media-body align-self-center">
                              <h6>YENİ BALON REZERVASYONU</h6>
                              <p>AYNI MİSAFİRLER için BALON rezervasyonu mu vermek istiyorsunuz?</p>
                              <b-button block @click="yeniBalonRezervasyonuVer()" variant="warning">Balon Rezervasyonu
                                Ver</b-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="card">
                        <div class="card-body">
                          <div class="media feather-main">
                            <div class="img-icon-block">
                              <img src="../../assets/images/icons/transfer.png" alt="" />
                            </div>
                            <div class="media-body align-self-center">
                              <h6>YENİ TRANSFER REZERVASYONU</h6>
                              <p>AYNI MİSAFİRLER için TRANSFER rezervasyonu mu vermek istiyorsunuz?</p>
                              <b-button block @click="yeniTransferRezervasyonuVer()" variant="danger">Transfer
                                Rezervasyonu
                                Ver</b-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-modal>
            </b-tab>
            <b-tab title-link-class="p-3" title="ÇOK GÜNLÜ PRIVATE TUR REZERVASYONU VER">
              <GrupTurRezervasyonForm @onComplete="onCompleteGrup" :acenteId="acenteId"></GrupTurRezervasyonForm>
            </b-tab>
          </b-tabs>

        </div>

        <div class="tab-pane fade" :class="{ 'show active': showtab == 'regularrezervasyon' }" id="regular-rezervasyon"
          role="tabpanel" aria-labelledby="regularrezervasyon">
          <RegularRezervasyonForm @onComplete="onCompleteRegular" :misafirler="tasinacakRegularMisafirler"
            :acenteId="acenteId">
          </RegularRezervasyonForm>

          <b-modal id="regularRezervasyonModel" ref="regularRezervasyonModel" size="xl" centered
            title="Regular Rezervasyon" ok-title="Save Changes" class="theme-modal" :hide-footer="true">
            <div class="container-fluid bd-example-row grid-showcase">
              <div class="row">
                <div class="col-md-12 text-center mb-3">
                  <h3>
                    <feather type="check-circle" class="text-success"></feather> {{ regularRezervasyonMessage }}
                  </h3>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 ml-auto"><b-button variant="danger" @click="$router.push('/')" block><i
                      class="fa fa-times"></i> KAPAT</b-button></div>
                <div class="col-md-6  ml-auto">
                  <b-button block @click="regularRezDetayGit()" variant="info"><i class="fa fa-info-circle"></i> DETAYA
                    GİT</b-button>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="card">
                    <div class="card-body">
                      <div class="media feather-main">
                        <div class="img-icon-block">
                          <img src="../../assets/images/icons/regular.png" alt="" />
                        </div>
                        <div class="media-body align-self-center">
                          <h6>YENİ REGULAR TUR REZERVASYONU</h6>
                          <p>AYNI MİSAFİRLER için REGULAR tur rezervasyonu mu vermek istiyorsunuz?</p>
                          <b-button block @click="reg_YeniRegularRezervasyonuVer()" variant="primary">Regular Tur
                            Rezervasyonu Ver</b-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card">
                    <div class="card-body">
                      <div class="media feather-main">
                        <div class="img-icon-block">
                          <img src="../../assets/images/icons/private.png" alt="" />
                        </div>
                        <div class="media-body align-self-center">
                          <h6>YENİ PRIVATE TUR REZERVASYONU</h6>
                          <p>AYNI MİSAFİRLER için farklı bir PRIVATE tur rezervasyonu mu vermek istiyorsunuz?</p>
                          <b-button block @click="reg_YeniTurRezervasyonuVer()" variant="dark">Private Tur Rezervasyonu
                            Ver</b-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="card">
                    <div class="card-body">
                      <div class="media feather-main">
                        <div class="img-icon-block">
                          <img src="../../assets/images/icons/balon.png" alt="" />
                        </div>
                        <div class="media-body align-self-center">
                          <h6>YENİ BALON REZERVASYONU</h6>
                          <p>AYNI MİSAFİRLER için BALON rezervasyonu mu vermek istiyorsunuz?</p>
                          <b-button block @click="reg_YeniBalonRezervasyonuVer()" variant="warning">Balon Rezervasyonu
                            Ver</b-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card">
                    <div class="card-body">
                      <div class="media feather-main">
                        <div class="img-icon-block">
                          <img src="../../assets/images/icons/transfer.png" alt="" />
                        </div>
                        <div class="media-body align-self-center">
                          <h6>YENİ TRANSFER REZERVASYONU</h6>
                          <p>AYNI MİSAFİRLER için TRANSFER rezervasyonu mu vermek istiyorsunuz?</p>
                          <b-button block @click="reg_yeniTransferRezervasyonuVer()" variant="danger">Transfer
                            Rezervasyonu
                            Ver</b-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-modal>
        </div>

        <div class="tab-pane fade" :class="{ 'show active': showtab == 'balonrezervasyon' }" id="balon-rezervasyon"
          role="tabpanel" aria-labelledby="balonrezervasyon">
          <BalonRezervasyonForm @onComplete="onCompleteBalon" :misafirler="tasinacakBalonMisafirler"
            :acenteId="acenteId">
          </BalonRezervasyonForm>

          <b-modal id="balonRezervasyonModel" ref="balonRezervasyonModel" size="xl" centered title="Balon Rezervasyonu"
            ok-title="Save Changes" class="theme-modal" :hide-footer="true">
            <div class="container-fluid bd-example-row grid-showcase">
              <div class="row">
                <div class="col-md-12 text-center mb-3">
                  <h3>
                    <feather type="check-circle" class="text-success"></feather> {{ balonRezervasyonMessage }}
                  </h3>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 ml-auto"><b-button variant="danger" @click="$router.push('/')" block><i
                      class="fa fa-times"></i> KAPAT</b-button></div>
                <div class="col-md-6  ml-auto">
                  <b-button block @click="balonRezDetayGit()" variant="info"><i class="fa fa-info-circle"></i> DETAYA
                    GİT</b-button>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="card">
                    <div class="card-body">
                      <div class="media feather-main">
                        <div class="img-icon-block">
                          <img src="../../assets/images/icons/balon.png" alt="" />
                        </div>
                        <div class="media-body align-self-center">
                          <h6>YENİ BALON REZERVASYONU</h6>
                          <p>AYNI MİSAFİRLER için BALON rezervasyonu mu vermek istiyorsunuz?</p>
                          <b-button block @click="bal_YeniBalonRezervasyonuVer()" variant="warning">Balon Rezervasyonu
                            Ver</b-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card">
                    <div class="card-body">
                      <div class="media feather-main">
                        <div class="img-icon-block">
                          <img src="../../assets/images/icons/private.png" alt="" />
                        </div>
                        <div class="media-body align-self-center">
                          <h6>YENİ PRIVATE TUR REZERVASYONU</h6>
                          <p>AYNI MİSAFİRLER için farklı bir PRIVATE tur rezervasyonu mu vermek istiyorsunuz?</p>
                          <b-button block @click="bal_YeniTurRezervasyonuVer()" variant="dark">Private Tur Rezervasyonu
                            Ver</b-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-md-6">
                  <div class="card">
                    <div class="card-body">
                      <div class="media feather-main">
                        <div class="img-icon-block">
                          <img src="../../assets/images/icons/regular.png" alt="" />
                        </div>
                        <div class="media-body align-self-center">
                          <h6>YENİ REGULAR TUR REZERVASYONU</h6>
                          <p>AYNI MİSAFİRLER için REGULAR tur rezervasyonu mu vermek istiyorsunuz?</p>
                          <b-button block @click="bal_YeniRegularRezervasyonuVer()" variant="primary">Regular Tur
                            Rezervasyonu Ver</b-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card">
                    <div class="card-body">
                      <div class="media feather-main">
                        <div class="img-icon-block">
                          <img src="../../assets/images/icons/transfer.png" alt="" />
                        </div>
                        <div class="media-body align-self-center">
                          <h6>YENİ TRANSFER REZERVASYONU</h6>
                          <p>AYNI MİSAFİRLER için TRANSFER rezervasyonu mu vermek istiyorsunuz?</p>
                          <b-button block @click="bal_yeniTransferRezervasyonuVer()" variant="danger">Transfer
                            Rezervasyonu
                            Ver</b-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-modal>
        </div>

        <div class="tab-pane fade" :class="{ 'show active': showtab == 'transfer' }" id="transfer" role="tabpanel"
          aria-labelledby="transfer">
          <!--<div class="alert alert-info">YAKINDA HİZMETİNİZDE</div>-->
          <TransferRezervasyonForm @onComplete="onCompleteTransfer" :rezervasyonVerildi="transferVerildi"
            :misafirler="tasinacakTransferMisafirler" :acenteId="acenteId"></TransferRezervasyonForm>

          <b-modal id="transferRezervasyonModel" ref="transferRezervasyonModel" size="xl" centered
            title="Transfer Rezervasyonu" class="theme-modal" :hide-footer="true">
            <div class="container-fluid bd-example-row grid-showcase">
              <div class="row">
                <div class="col-md-12 text-center mb-3">
                  <h3>
                    <feather type="check-circle" class="text-success"></feather> {{ transferRezervasyonMessage }}
                  </h3>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 ml-auto"><b-button variant="danger" @click="$router.push('/')" block><i
                      class="fa fa-times"></i> KAPAT</b-button></div>
                <div class="col-md-6  ml-auto">
                  <b-button block @click="transferRezDetayGit()" variant="info"><i class="fa fa-info-circle"></i> DETAYA
                    GİT</b-button>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="card">
                    <div class="card-body">
                      <div class="media feather-main">
                        <div class="img-icon-block">
                          <img src="../../assets/images/icons/transfer.png" alt="" />
                        </div>
                        <div class="media-body align-self-center">
                          <h6>YENİ TRANSFER REZERVASYONU</h6>
                          <p>AYNI MİSAFİRLER için TRANSFER rezervasyonu mu vermek istiyorsunuz?</p>
                          <b-button block @click="tra_YeniTransferRezervasyonuVer()" variant="danger">Transfer
                            Rezervasyonu
                            Ver</b-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card">
                    <div class="card-body">
                      <div class="media feather-main">
                        <div class="img-icon-block">
                          <img src="../../assets/images/icons/private.png" alt="" />
                        </div>
                        <div class="media-body align-self-center">
                          <h6>YENİ PRIVATE TUR REZERVASYONU</h6>
                          <p>AYNI MİSAFİRLER için farklı bir PRIVATE tur rezervasyonu mu vermek istiyorsunuz?</p>
                          <b-button block @click="tra_YeniTurRezervasyonuVer()" variant="dark">Private Tur Rezervasyonu
                            Ver</b-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-md-6">
                  <div class="card">
                    <div class="card-body">
                      <div class="media feather-main">
                        <div class="img-icon-block">
                          <img src="../../assets/images/icons/regular.png" alt="" />
                        </div>
                        <div class="media-body align-self-center">
                          <h6>YENİ REGULAR TUR REZERVASYONU</h6>
                          <p>AYNI MİSAFİRLER için REGULAR tur rezervasyonu mu vermek istiyorsunuz?</p>
                          <b-button block @click="tra_YeniRegularRezervasyonuVer()" variant="primary">Regular Tur
                            Rezervasyonu Ver</b-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card">
                    <div class="card-body">
                      <div class="media feather-main">
                        <div class="img-icon-block">
                          <img src="../../assets/images/icons/balon.png" alt="" />
                        </div>
                        <div class="media-body align-self-center">
                          <h6>YENİ BALON REZERVASYONU</h6>
                          <p>AYNI MİSAFİRLER için BALON rezervasyonu mu vermek istiyorsunuz?</p>
                          <b-button block @click="tra_YeniBalonRezervasyonuVer()" variant="warning">Balon
                            Rezervasyonu
                            Ver</b-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-modal>
        </div>
      </div>
    </div>

    <DuyuruGosterimModal />
  </div>
</template>
<script>
import TurRezervasyonForm from "@/components/c_tur_rezervasyon_form";
import GrupTurRezervasyonForm from "@/components/c_tur_rezervasyon_grup_form"
import RegularRezervasyonForm from "@/components/c_regular_rezervasyon_form";
import BalonRezervasyonForm from "@/components/c_balon_rezervasyon_form";
import TransferRezervasyonForm from "@/components/c_transfer_rezervasyon_form";
import TurRezervasyonList from "@/components/c_tur_rezervasyon_dashboard_acente";
import DuyuruGosterimModal from "@/components/c_duyuru_gosterim";
import DuyuruGosterimSurekliModal from "@/components/c_duyuru_gosterim_surekli";
import Reklam from "@/components/c_reklam";
import AcenteTurBekleyenler from '@/components/c_acente_tur_yorum_bekleyen';

export default {
  data() {
    return {
      acenteId: 0,

      showtab: "dashboard",

      turRezervasyonSayi: 0,

      turRezervasyonId: '',
      turRezervasyonMessage: '',

      regularRezervasyonId: '',
      regularRezervasyonMessage: '',

      balonRezervasyonId: '',
      balonRezervasyonMessage: '',

      transferRezervasyonId: '',
      transferRezervasyonMessage: '',

      tasinacakTurMisafirler: null,
      tasinacakRegularMisafirler: null,
      tasinacakBalonMisafirler: null,
      tasinacakTransferMisafirler: null,

      transferVerildi: false,

      profilDurumu: "",
    };
  },
  components: {
    TurRezervasyonForm,
    GrupTurRezervasyonForm,
    RegularRezervasyonForm,
    BalonRezervasyonForm,
    TransferRezervasyonForm,
    TurRezervasyonList,
    DuyuruGosterimModal,
    DuyuruGosterimSurekliModal,
    Reklam,
    AcenteTurBekleyenler,
  },
  mounted() {
    let user = this.$user.getUser();
    this.acenteId = user.Kullanici.AcenteId;
    //this.turRezervasyonSayiLoad();
    this.profilDurumLoad();
  },
  methods: {
    changetab(tab) {
      this.showtab = tab;
    },

    onComplete(kayit) {
      let vm = this;

      let url = "/turRezervasyonlari/" + this.acenteId;
      let method = "post";
      let messageTitle = "Yeni Rezervasyon";

      vm.$connection.post(
        url,
        kayit,
        method,
        function (response) {
          if (response.Success) {
            vm.$refs['turRezervasyonModel'].show()

            vm.turRezervasyonId = response.Data;
            vm.turRezervasyonMessage = response.Message;
          }
        },
        function (error) {
          vm.$alert.messageWithTitle(messageTitle, error.Message, "error");
        }
      );
    },
    onCompleteGrup(kayit) {
      let vm = this;

      let url = "/turRezervasyonlari/grup/" + this.acenteId;
      let method = "post";
      let messageTitle = "Yeni Grup Rezervasyon";

      vm.$connection.post(
        url,
        kayit,
        method,
        function (response) {
          if (response.Success) {
            vm.$alert.messageWithFunction(
              messageTitle,
              response.Message,
              "success",
              function () {
                vm.$router.push("/turRezervasyon/list?grupKodu=" + response.Data);
              }
            );
          }
        },
        function (error) {
          vm.$alert.messageWithTitle(messageTitle, error.Message, "error");
        }
      );
    },
    turRezDetayGit() {
      this.$refs['turRezervasyonModel'].hide()
      this.$router.push("/turRezervasyon/detay/" + this.turRezervasyonId);
    },
    turMisafirSet(successFunc) {
      let vm = this;

      this.$connection.get("/turRezervasyonlari/" + this.turRezervasyonId,
        function (response) {
          if (response.Success) {
            successFunc(response.Data);
          } else {
            vm.$alert.messageWithTitle("Taşınacak Regular Detay", response.Message, "error");
          }
        }
      );
    },
    yeniTurRezervasyonuVer() {
      let vm = this;

      this.turMisafirSet(function (data) {
        vm.tasinacakTurMisafirler = [];
        for (let i = 0; i < data.Misafirler.length; i++) {
          const element = data.Misafirler[i];
          vm.tasinacakTurMisafirler.push({
            Id: i,
            PasaportNo: element.PasaportNo,
            Uyruk: element.Uyruk,
            UyrukSelect: { value: element.Uyruk, text: element.Uyruk },
            AdSoyad: element.AdSoyad,
            Yas: element.Yas,
            Telefon: element.Telefon,
          });
        }
        vm.$refs['turRezervasyonModel'].hide();
        vm.showtab = "rezervasyon";
      });
    },
    yeniRegularRezervasyonuVer() {
      let vm = this;

      this.turMisafirSet(function (data) {
        vm.tasinacakRegularMisafirler = [];
        for (let i = 0; i < data.Misafirler.length; i++) {
          const element = data.Misafirler[i];
          vm.tasinacakRegularMisafirler.push({
            Id: i,
            PasaportNo: element.PasaportNo,
            Uyruk: element.Uyruk,
            UyrukSelect: { value: element.Uyruk, text: element.Uyruk },
            AdSoyad: element.AdSoyad,
            Yas: element.Yas,
            Telefon: element.Telefon,
          });
        }
        vm.$refs['turRezervasyonModel'].hide();
        vm.showtab = "regularrezervasyon";
      });
    },
    yeniBalonRezervasyonuVer() {
      let vm = this;

      this.turMisafirSet(function (data) {
        vm.tasinacakBalonMisafirler = [];
        for (let i = 0; i < data.Misafirler.length; i++) {
          const element = data.Misafirler[i];
          vm.tasinacakBalonMisafirler.push({
            Id: i,
            PasaportNo: element.PasaportNo,
            Uyruk: element.Uyruk,
            UyrukSelect: { value: element.Uyruk, text: element.Uyruk },
            AdSoyad: element.AdSoyad,
            Yas: element.Yas,
            Telefon: element.Telefon,
          });
        }
        vm.$refs['turRezervasyonModel'].hide();
        vm.showtab = "balonrezervasyon";
      });
    },
    yeniTransferRezervasyonuVer() {
      let vm = this;

      this.turMisafirSet(function (data) {
        vm.tasinacakTransferMisafirler = [];
        for (let i = 0; i < data.Misafirler.length; i++) {
          const element = data.Misafirler[i];
          vm.tasinacakTransferMisafirler.push({
            Id: i,
            PasaportNo: element.PasaportNo,
            Uyruk: element.Uyruk,
            UyrukSelect: { value: element.Uyruk, text: element.Uyruk },
            AdSoyad: element.AdSoyad,
            Cinsiyet: 'E'
          });
        }
        vm.$refs['turRezervasyonModel'].hide();
        vm.showtab = "transfer";
      });
    },

    onCompleteRegular(kayit) {
      let vm = this;

      let url = "/regularRezervasyonlar/istek/" + this.acenteId;
      let method = "post";
      let messageTitle = "Yeni Regular Rezervasyon";

      vm.$connection.post(
        url,
        kayit,
        method,
        function (response) {
          if (response.Success) {
            vm.$refs['regularRezervasyonModel'].show()

            vm.regularRezervasyonId = response.Data;
            vm.regularRezervasyonMessage = response.Message;
          }
        },
        function (error) {
          vm.$alert.messageWithTitle(messageTitle, error.Message, "error");
        }
      );
    },
    regularRezDetayGit() {
      this.$refs['regularRezervasyonModel'].hide()
      this.$router.push("/regularRezervasyon/istekdetay/" + this.regularRezervasyonId);
    },
    regularMisafirSet(successFunc) {
      let vm = this;

      this.$connection.get("/regularRezervasyonlar/istek/" + this.regularRezervasyonId,
        function (response) {
          if (response.Success) {
            successFunc(response.Data);
          } else {
            vm.$alert.messageWithTitle("Taşınacak Regular Detay", response.Message, "error");
          }
        }
      );
    },
    reg_YeniRegularRezervasyonuVer() {
      let vm = this;

      this.regularMisafirSet(function (data) {
        vm.tasinacakRegularMisafirler = [];
        for (let i = 0; i < data.Misafirler.length; i++) {
          const element = data.Misafirler[i];
          vm.tasinacakRegularMisafirler.push({
            Id: i,
            PasaportNo: element.PasaportNo,
            Uyruk: element.Uyruk,
            UyrukSelect: { value: element.Uyruk, text: element.Uyruk },
            AdSoyad: element.AdSoyad,
            Yas: element.Yas,
            Telefon: element.Telefon,
          });
        }
        vm.$refs['regularRezervasyonModel'].hide();
        vm.showtab = "regularrezervasyon";
      });
    },
    reg_YeniTurRezervasyonuVer() {
      let vm = this;

      this.regularMisafirSet(function (data) {
        vm.tasinacakTurMisafirler = [];
        for (let i = 0; i < data.Misafirler.length; i++) {
          const element = data.Misafirler[i];
          vm.tasinacakTurMisafirler.push({
            Id: i,
            PasaportNo: element.PasaportNo,
            Uyruk: element.Uyruk,
            UyrukSelect: { value: element.Uyruk, text: element.Uyruk },
            AdSoyad: element.AdSoyad,
            Yas: element.Yas,
            Telefon: element.Telefon,
          });
        }
        vm.$refs['regularRezervasyonModel'].hide();
        vm.showtab = "rezervasyon";
      });
    },
    reg_YeniBalonRezervasyonuVer() {
      let vm = this;

      this.regularMisafirSet(function (data) {
        vm.tasinacakBalonMisafirler = [];
        for (let i = 0; i < data.Misafirler.length; i++) {
          const element = data.Misafirler[i];
          vm.tasinacakBalonMisafirler.push({
            Id: i,
            PasaportNo: element.PasaportNo,
            Uyruk: element.Uyruk,
            UyrukSelect: { value: element.Uyruk, text: element.Uyruk },
            AdSoyad: element.AdSoyad,
            Yas: element.Yas,
            Telefon: element.Telefon,
          });
        }
        vm.$refs['regularRezervasyonModel'].hide();
        vm.showtab = "balonrezervasyon";
      });
    },
    reg_yeniTransferRezervasyonuVer() {
      let vm = this;

      this.regularMisafirSet(function (data) {
        vm.tasinacakTransferMisafirler = [];
        for (let i = 0; i < data.Misafirler.length; i++) {
          const element = data.Misafirler[i];
          vm.tasinacakTransferMisafirler.push({
            Id: i,
            PasaportNo: element.PasaportNo,
            Uyruk: element.Uyruk,
            UyrukSelect: { value: element.Uyruk, text: element.Uyruk },
            AdSoyad: element.AdSoyad,
            Cinsiyet: 'E',
          });
        }
        vm.$refs['regularRezervasyonModel'].hide();
        vm.showtab = "transfer";
      });
    },

    onCompleteBalon(kayit) {
      let vm = this;

      let url = "/balonRezervasyonlari/" + this.acenteId;
      let method = "post";
      let messageTitle = "Yeni Balon Rezervasyonu";

      vm.$connection.post(
        url,
        kayit,
        method,
        function (response) {
          if (response.Success) {
            vm.$refs['balonRezervasyonModel'].show()

            vm.balonRezervasyonId = response.Data;
            vm.balonRezervasyonMessage = response.Message;
          }
        },
        function (error) {
          vm.$alert.messageWithTitle(messageTitle, error.Message, "error");
        }
      );
    },
    balonRezDetayGit() {
      this.$refs['balonRezervasyonModel'].hide()
      this.$router.push("/balonRezervasyon/detay/" + this.balonRezervasyonId);
    },
    balonMisafirSet(successFunc) {
      let vm = this;

      this.$connection.get("/balonRezervasyonlari/" + this.balonRezervasyonId,
        function (response) {
          if (response.Success) {
            successFunc(response.Data);
          } else {
            vm.$alert.messageWithTitle("Taşınacak Balon Detay", response.Message, "error");
          }
        }
      );
    },
    bal_YeniRegularRezervasyonuVer() {
      let vm = this;

      this.balonMisafirSet(function (data) {
        vm.tasinacakRegularMisafirler = [];
        for (let i = 0; i < data.Misafirler.length; i++) {
          const element = data.Misafirler[i];
          vm.tasinacakRegularMisafirler.push({
            Id: i,
            PasaportNo: element.PasaportNo,
            Uyruk: element.Uyruk,
            UyrukSelect: { value: element.Uyruk, text: element.Uyruk },
            AdSoyad: element.AdSoyad,
            Yas: element.Yas,
            Telefon: element.Telefon,
          });
        }
        vm.$refs['balonRezervasyonModel'].hide();
        vm.showtab = "regularrezervasyon";
      });
    },
    bal_YeniTurRezervasyonuVer() {
      let vm = this;

      this.balonMisafirSet(function (data) {
        vm.tasinacakTurMisafirler = [];
        for (let i = 0; i < data.Misafirler.length; i++) {
          const element = data.Misafirler[i];
          vm.tasinacakTurMisafirler.push({
            Id: i,
            PasaportNo: element.PasaportNo,
            Uyruk: element.Uyruk,
            UyrukSelect: { value: element.Uyruk, text: element.Uyruk },
            AdSoyad: element.AdSoyad,
            Yas: element.Yas,
            Telefon: element.Telefon,
          });
        }
        vm.$refs['balonRezervasyonModel'].hide();
        vm.showtab = "rezervasyon";
      });
    },
    bal_YeniBalonRezervasyonuVer() {
      let vm = this;

      this.balonMisafirSet(function (data) {
        vm.tasinacakBalonMisafirler = [];
        for (let i = 0; i < data.Misafirler.length; i++) {
          const element = data.Misafirler[i];
          vm.tasinacakBalonMisafirler.push({
            Id: i,
            PasaportNo: element.PasaportNo,
            Uyruk: element.Uyruk,
            UyrukSelect: { value: element.Uyruk, text: element.Uyruk },
            AdSoyad: element.AdSoyad,
            Yas: element.Yas,
            Telefon: element.Telefon,
          });
        }
        vm.$refs['balonRezervasyonModel'].hide();
        vm.showtab = "balonrezervasyon";
      });
    },
    bal_yeniTransferRezervasyonuVer() {
      let vm = this;

      this.balonMisafirSet(function (data) {
        vm.tasinacakTransferMisafirler = [];
        for (let i = 0; i < data.Misafirler.length; i++) {
          const element = data.Misafirler[i];
          vm.tasinacakTransferMisafirler.push({
            Id: i,
            PasaportNo: element.PasaportNo,
            Uyruk: element.Uyruk,
            UyrukSelect: { value: element.Uyruk, text: element.Uyruk },
            AdSoyad: element.AdSoyad,
            Cinsiyet: 'E'
          });
        }
        vm.$refs['balonRezervasyonModel'].hide();
        vm.showtab = "transfer";
      });
    },

    onCompleteTransfer(kayit) {
      let vm = this;

      let url = "/laalTransferler/ekle/" + this.acenteId;
      let method = "post";
      let messageTitle = "Yeni Transfer Rezervasyonu";

      vm.$connection.post(
        url,
        kayit,
        method,
        function (response) {
          if (response.Success) {
            vm.$refs['transferRezervasyonModel'].show()
            vm.transferVerildi = true;

            vm.transferRezervasyonId = response.Data;
            vm.transferRezervasyonMessage = response.Message;
          }
        },
        function (error) {
          vm.$alert.messageWithTitle(messageTitle, error.Message, "error");
        }
      );
    },
    transferRezDetayGit() {
      this.$refs['transferRezervasyonModel'].hide()
      this.$router.push("/transfer/detay/" + this.transferRezervasyonId);
    },
    transferMisafirSet(successFunc) {
      let vm = this;

      this.$connection.get("/laalTransferler/" + this.transferRezervasyonId,
        function (response) {
          if (response.Success) {
            successFunc(response.Data);
          } else {
            vm.$alert.messageWithTitle("Taşınacak Transfer Detay", response.Message, "error");
          }
        }
      );
    },
    tra_YeniRegularRezervasyonuVer() {
      let vm = this;

      this.transferMisafirSet(function (data) {
        vm.tasinacakRegularMisafirler = [];
        for (let i = 0; i < data.Detay.Yolcular.length; i++) {
          const element = data.Detay.Yolcular[i];
          vm.tasinacakRegularMisafirler.push({
            Id: i,
            PasaportNo: element.PasaportNo,
            Uyruk: element.Ulke,
            UyrukSelect: { value: element.Ulke, text: element.Ulke },
            AdSoyad: element.AdSoyad,
            Yas: 2,
            Telefon: ""
          });
        }
        vm.$refs['transferRezervasyonModel'].hide();
        vm.showtab = "regularrezervasyon";
      });
    },
    tra_YeniTurRezervasyonuVer() {
      let vm = this;

      this.transferMisafirSet(function (data) {
        vm.tasinacakTurMisafirler = [];
        for (let i = 0; i < data.Detay.Yolcular.length; i++) {
          const element = data.Detay.Yolcular[i];
          vm.tasinacakTurMisafirler.push({
            Id: i,
            PasaportNo: element.PasaportNo,
            Uyruk: element.Ulke,
            UyrukSelect: { value: element.Ulke, text: element.Ulke },
            AdSoyad: element.AdSoyad,
            Yas: 2,
            Telefon: ""
          });
        }
        vm.$refs['transferRezervasyonModel'].hide();
        vm.showtab = "rezervasyon";
      });
    },
    tra_YeniBalonRezervasyonuVer() {
      let vm = this;

      this.transferMisafirSet(function (data) {
        vm.tasinacakBalonMisafirler = [];
        for (let i = 0; i < data.Detay.Yolcular.length; i++) {
          const element = data.Detay.Yolcular[i];
          vm.tasinacakBalonMisafirler.push({
            Id: i,
            PasaportNo: element.PasaportNo,
            Uyruk: element.Ulke,
            UyrukSelect: { value: element.Ulke, text: element.Ulke },
            AdSoyad: element.AdSoyad,
            Yas: 2,
            Telefon: "",
          });
        }
        vm.$refs['transferRezervasyonModel'].hide();
        vm.showtab = "balonrezervasyon";
      });
    },
    tra_YeniTransferRezervasyonuVer() {
      let vm = this;

      this.transferMisafirSet(function (data) {
        vm.tasinacakTransferMisafirler = [];
        for (let i = 0; i < data.Detay.Yolcular.length; i++) {
          const element = data.Detay.Yolcular[i];
          vm.tasinacakTransferMisafirler.push({
            Id: i,
            PasaportNo: element.PasaportNo,
            Uyruk: element.Ulke,
            UyrukSelect: { value: element.Ulke, text: element.Ulke },
            AdSoyad: element.AdSoyad,
            Cinsiyet: element.Cinsiyet.substring(0, 1)
          });
        }
        vm.$refs['transferRezervasyonModel'].hide();
        vm.showtab = "transfer";
      });
    },

    turRezervasyonSayiLoad() {
      let vm = this;

      this.$connection.get(
        "/turRezervasyonlari/sayiForAcente",
        function (response) {
          vm.turRezervasyonSayi = response;
        }
      );
    },
    profilDurumLoad() {
      let vm = this;
      this.$connection.get("/acenteler/profilDurum", function (response) {
        vm.profilDurumu = response;
      });
    },
  },
};
</script>
