<template>
  <div>
    <Breadcrumbs main="Dashboard" title="Kontrol Paneli" />

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <b-form @submit="onFiltreSubmit" class="pull-right">
            <b-input-group prepend="Tarih">
              <template #prepend>
                <b-button @click="gun(-1)" variant="light"><i class="fa fa-angle-left"></i></b-button>
                <b-button @click="gun(+1)" variant="light"><i class="fa fa-angle-right"></i></b-button>
              </template>
              <datepicker input-class="datepicker-here form-control digits" v-model="filtre.Tarih" :language="tr"
                placeholder="Başlangıç Tarihi" :format="date_format">
              </datepicker>
            </b-input-group>
          </b-form>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <ul class="nav nav-tabs border-tab tabs-scoial" id="top-rez" role="tablist">
            <li class="nav-item" v-on:click="changetabRez('turRez')">
              <a class="nav-link" :class="[
            { 'show active': showtabRez == 'turRez' }
          ]" id="top-turrez" data-toggle="tab" role="tab" aria-controls="turRez" aria-selected="true">TUR
                REZERVASYONLARI <b-badge variant="primary">{{ turRez_sayisi }}</b-badge></a>
            </li>
            <li class="nav-item" v-on:click="changetabRez('regularRez')">
              <a class="nav-link" :class="[
            { 'show active': showtabRez == 'regularRez' }
          ]" id="top-regularrez" data-toggle="tab" role="tab" aria-controls="regularRez" aria-selected="true">REGULAR
                REZERVASYONLAR <b-badge variant="primary">{{ regularRez_sayisi }}</b-badge></a>
            </li>
          </ul>
        </div>
      </div>
      <div class="tab-content" id="top-tabRezContent">
        <div class="tab-pane fade" :class="{ 'show active': showtabRez == 'turRez' }" id="turRez" role="tabpanel"
          aria-labelledby="turRez">
          <TurRezervasyonList :tarih="filtre.Tarih" v-if="filtre.Tarih != null" @onKayitSayisi="onTurRezSayisi" />
        </div>

        <div class="tab-pane fade" :class="{ 'show active': showtabRez == 'regularRez' }" id="regularRez"
          role="tabpanel" aria-labelledby="regularRez">
          <RegularRezervasyonList :tarih="filtre.Tarih" v-if="filtre.Tarih != null"
            @onKayitSayisi="onRegularRezSayisi" />
        </div>
      </div>

      <DuyuruGosterimModal />
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import { tr } from "vuejs-datepicker/dist/locale";
import TurRezervasyonList from "@/components/c_tur_rezervasyon_dashboard_aracFirma";
import RegularRezervasyonList from "@/components/c_regular_rezervasyon_dashboard_aracFirma";
import DuyuruGosterimModal from "@/components/c_duyuru_gosterim";
import DuyuruGosterimSurekliModal from "@/components/c_duyuru_gosterim_surekli";

export default {
  data() {
    return {
      showtabRez: "turRez",

      date_format: "dd.MM.yyyy",
      tr: tr,

      filtre: {
        Tarih: null,
      },

      turRez_sayisi: 0,
      regularRez_sayisi: 0
    };
  },
  components: {
    Datepicker,
    TurRezervasyonList,
    RegularRezervasyonList,
    DuyuruGosterimModal,
    DuyuruGosterimSurekliModal
  },
  mounted() {
    let user = this.$user.getUser();
    if (user.Kullanici.RedirectUrl !== "/dashboard/aracFirma")
      this.$router.push(user.Kullanici.RedirectUrl);

    let bugun = new Date();
    this.filtre.Tarih = bugun;
  },
  methods: {
    onFiltreSubmit(target) {
      target.preventDefault();
    },
    gun(sayi) {
      this.filtre.Tarih = this.$formatHelper.addDay(
        new Date(this.filtre.Tarih),
        sayi
      );
    },
    onTurRezSayisi(sayi) {
      this.turRez_sayisi = sayi;
    },
    onRegularRezSayisi(sayi) {
      this.regularRez_sayisi = sayi;
    },
    changetabRez(tab) {
      this.showtabRez = tab;
    }
  }
};
</script>
