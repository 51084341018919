<template>
  <div class="col-md-6" v-if="transfer != undefined">
    <b-card header-tag="div" :header-bg-variant="renk" :bg-variant="iptal ? 'light':'white'" text-variant="dark" class="card-absolute">
      <h5 slot="header">
        Seyehat Bilgileri 
        <b-badge variant="info">{{ transfer.Donus ? 'Dönüş' : 'Geliş' }}</b-badge>
        <b-badge variant="dark" v-if="iptal">İptal Edildi</b-badge>
      </h5>
      <table class="product-page-width">
        <tbody>
          <tr>
            <th>Kod</th>
            <td>
              <b-badge variant="primary">{{ transfer.Kod }}</b-badge>
            </td>
          </tr>
          <tr>
            <th>Tarih</th>
            <td>
              <c-tarih-cell :kayitTarihi="transfer.Tarih" :rowKey="transfer.Kod"></c-tarih-cell>
            </td>
          </tr>
          <tr>
            <th>Saat</th>
            <td>
              <b-badge variant="info"><c-saat-cell :tarih="transfer.Tarih"></c-saat-cell></b-badge>
            </td>
          </tr>
          <tr>
            <th>Durum</th>
            <td>
              <b-badge variant="primary">{{ transfer.DurumStr }}</b-badge>
            </td>
          </tr>
          <tr>
            <th>Güzergah</th>
            <td>
              <div v-if="transfer.Donus == false">
                <b-badge variant="dark">{{ transfer.Detay.Bolge.Adi }}</b-badge>
                <b-badge variant="info">{{ transfer.Detay.Rezervasyon.Nereye }} ({{ transfer.Detay.HedefBolge.Adi
                  }})</b-badge>
              </div>
              <div v-if="transfer.Donus == true">
                <b-badge variant="info">{{ transfer.Detay.Rezervasyon.Nereye }} ({{ transfer.Detay.HedefBolge.Adi
                  }})</b-badge>
                <b-badge variant="dark">{{ transfer.Detay.Bolge.Adi }}</b-badge>
              </div>
            </td>
          </tr>
          <tr>
            <th>Otel Adresi</th>
            <td>{{ transfer.Detay.Rezervasyon.NereyeAdres }}</td>
          </tr>
          <tr>
            <th>Açıklama</th>
            <td>{{ transfer.Detay.Rezervasyon.Aciklama }}</td>
          </tr>
          <tr>
            <th>Uçuş Kodu</th>
            <td>{{ transfer.Detay.Rezervasyon.UcusKodu }}</td>
          </tr>
          <tr v-if="transfer.Detay.AracTipi != null">
            <th>Araç Tipi</th>
            <td>
              {{ transfer.Detay.AracTipi.Adi }}
            </td>
          </tr>
          <tr>
            <th>Kişi Sayısı</th>
            <td>
              <b-badge variant="info">{{ transfer.Detay.Rezervasyon.KisiSayisi }}</b-badge>
            </td>
          </tr>
        </tbody>
      </table>
    </b-card>

    <CTransferRezervasyonTutarlar :transfer="transfer" v-if="transfer !== undefined" />

    <b-card header-tag="div" v-if="transfer.Detay.Sofor.AdSoyad != null" :header-bg-variant="renk"
      class="card-absolute">
      <h5 slot="header">Araç <b-badge variant="info">{{ transfer.Donus ? 'Dönüş' : 'Geliş' }}</b-badge></h5>
      <table class="product-page-width">
        <tbody>
          <tr>
            <td colspan="2" class="text-center">
              <img :src="transfer.Detay.Sofor.Resim" />
            </td>
          </tr>
          <tr>
            <th>Plaka</th>
            <td>{{ transfer.Detay.Sofor.Plaka }}</td>
          </tr>
          <tr>
            <th>Marka / Model</th>
            <td>{{ transfer.Detay.Sofor.Marka }} / {{ transfer.Detay.Sofor.Model }}</td>
          </tr>
          <tr>
            <th>Araç Takip</th>
            <td>{{ transfer.Detay.Sofor.isAracTakip ? 'Var' : 'Yok' }}</td>
          </tr>
          <tr>
            <th>Şoför</th>
            <td>{{ transfer.Detay.Sofor.AdSoyad }}</td>
          </tr>
          <tr>
            <th>Şoför Telefon</th>
            <td>{{ transfer.Detay.Sofor.GSM }}</td>
          </tr>
        </tbody>
      </table>
    </b-card>

    <b-list-group>
      <b-list-group-item router-component-name="router-link" tag="a" href="#" v-if="isBtnDuzelt"
        :disabled="disable_duzelt_state" @click="rezervasyonDuzeltAc()">
        <feather type="edit-3"></feather> Rezervasyon Düzelt
        <span>{{
    disable_duzelt_state
      ? "(Düzeltmek için uygun değildir.)"
      : ""
  }}</span>
      </b-list-group-item>
      <b-list-group-item router-component-name="router-link" v-if="isBtnIptal" :disabled="disable_iptal_state" tag="a"
        href="#" @click="iptalEt()">
        <feather type="slash"></feather> İptal
        {{
    disable_iptal_state ? "(İptal için uygun değildir.)" : ""
  }}
      </b-list-group-item>
    </b-list-group>

    <b-modal :id="'rezervasyonDuzeltForm_' + transfer.Kod" size="xl" centered title="Rezervasyon Düzelt" class="theme-modal"
      :hide-footer="true">
      <b-form @submit="rezervasyonDuzelt">
        <CTransferRezervasyonDuzelt :id="transfer.Id" :rezervasyon="duzeltRezervasyon" @onComplete="rezervasyonDuzelt"
          v-if="duzeltRezervasyon != null" />
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import CTransferRezervasyonTutarlar from "./c_transfer_rezervasyon_tutarlar";
import CTransferRezervasyonDuzelt from '@/components/c_transfer_rezervasyon_duzelt_form';

export default {
  props: {
    pTransferId: "",
    pTransfer: undefined
  },
  data() {
    return {
      transfer: this.pTransfer,
      transferId: this.pTransferId,

      isTransferShow: false,
      isMisafirShow: false,
      isDetayShow: true, //Herkes görecek
      isIslemShow: false,
      isBtnDuzelt: false,
      isBtnAcenteDetay: false,
      isBtnYonetim: false,
      isBtnIptal: false,

      duzeltRezervasyon: null,
      duzeltYolcu: null,

      renk: '',
      iptal: false
    };
  },
  components: {
    CTransferRezervasyonTutarlar,
    CTransferRezervasyonDuzelt
  },
  computed: {
    disable_duzelt_state() {
      if (this.transfer.Durum == 0 || this.transfer.Durum == 1) {
        if (new Date(this.transfer.Tarih) > new Date()) return false;
      }

      return true;
    },
    disable_iptal_state() {
      if (new Date(this.transfer.Tarih) < new Date() || this.transfer.Durum == 3 || this.transfer.Durum == 5) {
        return true;
      }

      return false;
    }
  },
  mounted() {
    if (this.pTransfer == undefined && this.transferId !== "") {
      //Detayı çek
      this.onDetay();
    }
    if (this.transfer !== undefined) {
      //Detayı çek
      this.transferId = this.transfer.Id;
      this.renk = this.transfer.Durum == 5 ? 'danger' : (this.transfer.Donus ? 'warning' : 'primary');
      this.iptal = this.transfer.Durum == 5;
      this.izinleriAyarla();
    }
  },
  methods: {
    onDetay() {
      let vm = this;

      this.$connection.get(
        "/laalTransferler/" + this.transferId,
        function (response) {
          if (response.Success) {
            vm.transfer = response.Data;

            vm.renk = vm.transfer.Durum == 5 ? 'danger' : (vm.transfer.Donus ? 'warning' : 'primary');
            vm.iptal = vm.transfer.Durum == 5;

            vm.izinleriAyarla();
          } else {
            vm.$alert.messageWithTitle("Transfer Detay", response.Message, "error");
          }
        }
      );
    },
    izinleriAyarla() {
      let user = this.$user.getUser();

      switch (user.Kullanici.KullaniciTipi) {
        //Acente Yönetici
        case 10:
          this.isTransferShow = true;
          this.isMisafirShow = true;
          this.isDetayShow = true;
          this.isDetayAcenteYoneticiFinansShow = true;
          this.isBtnDuzelt = true;
          this.isBtnIptal = true;
          this.isAracSozlesme = true;
          this.isRehberSozlesme = true;
          break;

        //Acente Kullanıcı
        case 15:
          this.isTransferShow = true;
          this.isMisafirShow = true;
          this.isDetayShow = true;
          this.isBtnDuzelt = true;
          this.isBtnIptal = true;
          this.isAracSozlesme = true;
          this.isRehberSozlesme = true;
          break;

        //Rehber
        case 20:
          break;

        //Araç Firması
        case 30:
        case 35:
          break;

        //Araç
        case 40:
          break;

        //Merkez
        case 80:
        case 85:
        case 100:
          this.isTransferShow = true;
          this.isMisafirShow = true;
          this.isDetayShow = true;
          this.isIslemShow = true;
          this.isBtnAcenteDetay = true;
          this.isBtnYonetim = true;
          this.isBtnDuzelt = true;
          this.isBtnKismiDuzelt = true;
          this.isBtnIptal = true;
          this.isAracSozlesme = true;
          this.isRehberSozlesme = true;
          break;

        default:
          break;
      }

      switch (this.transfer.Durum) {
        case 2:
          //Tamamlandı
          break;

        case 1:
        case 0:
          //Yeni veya onaylandı
          break;

        case 100:
          //İptal
          this.isBtnIptal = false;
          break;

        default:
          break;
      }
    },
    rezervasyonDuzeltAc() {
      this.duzeltRezervasyon = this.transfer.Detay.Rezervasyon;
      this.$bvModal.show("rezervasyonDuzeltForm_" + this.transfer.Kod);
    },
    rezervasyonDuzelt(kayit) {
      let vm = this;

      let url = "/laalTransferler/Guncelle/" + this.transferId;
      let method = "put";
      let messageTitle = "Transfer Rezervasyonu Güncelle";

      vm.$connection.post(
        url,
        kayit,
        method,
        function (response) {
          if (response.Success) {
            vm.$alert.messageWithFunction(
              messageTitle,
              response.Message,
              "success",
              function () {
                vm.onDetay();
                vm.$bvModal.hide("rezervasyonDuzeltForm");
              }
            );
          }
        },
        function (error) {
          vm.$alert.messageWithTitle(messageTitle, error.Message, "error");
        }
      );
    },
    iptalEt() {
      let vm = this;
      this.$alert.confirm(
        "İptal",
        "Rezervasyon iptal edilecektir. Emin misiniz?",
        "warning",
        function () {
          vm.$connection.post(
            "/laalTransferler/iptal/" + vm.transfer.Id + "/" + vm.transfer.Kod,
            null,
            "put",
            function () {
              vm.$alert.messageWithFunction(
                "İptal",
                "Rezervasyon iptal edildi.",
                "success",
                function () {
                  vm.onDetay();
                }
              );
            }
          );
        }
      );
    }
  }
};
</script>
