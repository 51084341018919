<template>
  <div>
    <Breadcrumbs main="Acenteler" title="Onay Bekleyenler" :links="breadLinks" />

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <c-table :tablefields="tablefields" :items="items">
                <template #cell(Adi)="row">
                  <div>{{ row.item.Adi }}<br /></div>
                  <b-btn-group>
                    <router-link :to="`/acente/detay/${row.item.Id}`" class="btn btn-dark btn-xs">Detay</router-link>
                    <router-link :to="`/acente/kayit/${row.item.Id}`" class="btn btn-info btn-xs">Düzelt</router-link>
                    <b-button size="xs" @click="onayla(row.item)" variant="success">Onayla</b-button>
                    <b-button size="xs" @click="iptalEt(row.item)" variant="warning">İptal Et</b-button>
                  </b-btn-group>
                </template>
                <template #cell(Email)="data">
                  <a :href="'mailto:' + data.value">{{ data.value }}</a>
                </template>
                <template #cell(KayitTarihi)="row">
                  <c-tarih-cell :kayitTarihi="row.item.KayitTarihi" :guncelTarih="row.item.GuncelTarih"
                    :rowKey="row.item.Id"></c-tarih-cell>
                </template>
              </c-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      breadLinks: [
        { path: "/Acente/list", title: "Acenteler", icon: "chevron-left" },
        { path: "/acente/kayit", title: "Ekle", icon: "plus" },
      ],
      tablefields: [
        { key: "Adi", label: "Adı", sortable: true },
        { key: "YetkiliKisi", label: "Yetkili", sortable: true },
        { key: "SehirAdi", label: "Şehir", sortable: true },
        { key: "GSM", label: "GSM", sortable: true, thClass: "sabitKol" },
        { key: "Email", label: "Email", sortable: true, thClass: "sabitKol" },
        { key: "KullaniciAdi", label: "Kullanıcı", sortable: true },
        { key: "KayitTarihi", label: "Kayıt Tarihi", sortable: true },
      ],

      items: [],
    };
  },
  mounted() {
    this.onList();
  },
  methods: {
    onList() {
      let vm = this;

      this.$connection.get("/acenteler/onayBekleyenler", function (response) {
        vm.items = response;
      });
    },
    onayla(item) {
      let vm = this;
      this.$alert.confirm(
        "Onayla",
        "Acenteyı onaylamak istediğinize emin misiniz?",
        "warning",
        function () {
          vm.$connection.get(
            "/acenteler/onayla/" + item.Id,
            function (response) {
              if (response.Success) {
                vm.$alert.messageWithFunction(
                  "Onaylandı",
                  "Acente onaylandı.",
                  "success",
                  function () {
                    vm.$listHelper.removeItem(vm.items, item);
                  }
                );
              }
            }
          );
        }
      );
    },
    iptalEt(item) {
      let vm = this;
      this.$alert.confirm(
        "İptal Et",
        "Acente aynı bilgilerle bir daha üye olamayacak. İptal etmek istedğinize emin misiniz?",
        "warning",
        function () {
          vm.$connection.get(
            "/acenteler/durumDegistir/" + item.Id + "/100",
            function (response) {
              if (response.Success) {
                vm.$alert.messageWithFunction(
                  "İptal Edildi",
                  "Acente iptal edildi.",
                  "success",
                  function () {
                    vm.$listHelper.removeItem(vm.items, item);
                  }
                );
              }
            }
          );
        }
      );
    },
  },
};
</script>
